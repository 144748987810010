<script>
import {mapGetters} from "vuex";
import {convertToFull} from '@/utils/deckConversion';

export default {
  name: "PlayerCardBack",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    order: {
      type: String,
      required: true,
    },
    me: {
      type: Boolean,
      required: true,
    },
    player: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      open: false,
      timer: null,
    }
  },
  computed: {
    ...mapGetters('game', ['myInfo', 'isShowdown', 'isShowResult', 'canPlayGame', 'bestHandDetails', 'isDebugging']),
    ...mapGetters('config', ['cardResourceNumber']),
    bestHand() {
      return this.bestHandDetails ? this.bestHandDetails.cards || [] : [];
    },
    cardStyle() {
      const styles = {
        visibility: this.visible ? 'visible' : 'hidden',
      };

      if (this.isDebugging) {
        styles.transform = '';
      }

      return styles;
    },
    isCardInBestHand() {
      if (!this.card || !this.bestHand.length) {
        return false;
      }

      return this.bestHand.indexOf(this.card.nick) !== -1;
    },
    isCardOpen() {
      return [
        this.isShowdown || this.isShowResult || this.isDebugging,
        this.player.playState === this.playState.PLAYING,
        this.canPlayGame
      ].every(is => !!is);
    },
    /**
     * @returns {*|null|{nick: *, rank: *, suit: *}}
     * nick:"Ks"
     * rank:"King"
     * suit:"Spade"
     */
    card() {
      if (!this.me && this.player) {
        const nick = this.player.hands[this.order === 'first' ? 0 : 1];

        if (!nick || nick && nick === 'backh') {
          return null;
        }

        const [suit, rank] = convertToFull(nick).split('_');

        return {
          nick,
          suit,
          rank,
        }
      }

      if (this.order === 'second') {
        return this.myInfo.hands[1] || null;
      }

      return this.myInfo.hands[0] || null;
    },
    cardAsset() {
      if (!this.card || !this.isCardOpen) {
        return require('@/assets/v2/img/card_back_1.webp');
      }

      let {suit, nick} = this.card;
      const rankAbbr = nick.charAt(0) || '';
      let rank = rankAbbr.toLowerCase() === 't' ? 10 : rankAbbr.toUpperCase();

      return require(`@/assets/v2/svg/deck${this.cardResourceNumber}/${suit.toLowerCase()}_${rank}.svg`);
    },
    cardStatus() {
      if (!this.isShowResult) {
        return '';
      }

      return this.isCardInBestHand ? 'active' : 'disabled';
    }
  }
}
</script>

<template>
  <div class="game_card"
       :class="[
       isCardOpen ? 'card_front' : 'card_back',
       order,
       cardStatus,
     ]"
       :style="cardStyle"
  >
    <img :src="cardAsset" alt="">
  </div>
</template>


<style>
.card_back.open {
  animation-duration: .5s;
  animation-delay: 160ms;
  animation-direction: alternate;
  animation-fill-mode: forwards;
  animation-name: card-open;
}

@keyframes card-open {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotateY(-180deg);
  }
}
</style>