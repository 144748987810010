const suits = {
    'spade': 's',
    'diamond': 'd',
    'clover': 'c',
    'heart': 'h'
};
const ranks = {
    'ace': 'A',
    'king': 'K',
    'queen': 'Q',
    'jack': 'J',
    '10': 'T',
    '9': '9',
    '8': '8',
    '7': '7',
    '6': '6',
    '5': '5',
    '4': '4',
    '3': '3',
    '2': '2'
};

const fullNameToShort = new Map();
const shortToFullName = new Map();

Object.entries(suits).forEach(([suit, suitInitial]) => {
    Object.entries(ranks).forEach(([rank, rankInitial]) => {
        const fullName = `${suit}_${rank}`;
        const shortName = `${rankInitial}${suitInitial}`;
        fullNameToShort.set(fullName, shortName);
        shortToFullName.set(shortName, fullName);
    });
});

const convertToShort = fullName => fullNameToShort.get(fullName);
const convertToFull = shortName => shortToFullName.get(shortName);

export {convertToShort, convertToFull, fullNameToShort, shortToFullName};